import { FC, useEffect, useState } from 'react';
//import clsx from 'clsx';
//import { EdLogoWelcome } from '@shared/icons';
import { useCookies } from 'react-cookie';
import { useIsChatEligible } from '@shared/hooks';
import { RoutePaths } from '@shared/utils';
import { useI18n } from '@i18n';
import { useAuth } from '@authentication';
import { useChat } from '@chat';

// TODO: remove after staging demo with LAUSD
function pickRandom<T>(array: T[]): T {
  if (array.length === 0) {
    throw new Error('Array is empty');
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}
export interface AskEdButtonProps {
  buttonCls?: string;
  iframeCls?: string;
}

export const AskEdButton: FC<AskEdButtonProps> = () => {
  const [hasOpened, setHasOpened] = useState(false);
  const [cookies] = useCookies(['access_token']);
  const { t } = useI18n();
  const isChatEligible = useIsChatEligible();
  const { user } = useAuth();
  const {
    //widgetUrl,
    isChatOpen,
    setIsChatOpen,
    //iframeRef,
    //onLoad,
    //device,
    //setDevice,
    setIsOpenedByNudge,
    setInitialMessage
  } = useChat();

  // const onClickHandler = useCallback(() => {
  //   setDevice('desktop');
  //   setIsChatOpen(true);
  //   setIsOpenedByNudge(false);
  // }, [setDevice, setIsChatOpen, setIsOpenedByNudge]);

  useEffect(() => {
    // This ensure to reset the chat state when the user logs out.
    // if not, the next user that signs in, the chat will NOT pop open
    return () => {
      setIsChatOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const enable_chat_in_this_env = import.meta.env.VITE_ZAMMO_ENABLE_WEBCHAT === '1';

    const corelibrary_Id = 'ZammoBotUIKitScript';
    const customscript_Id = 'ZammoBotUICustom';

    if (isChatEligible && enable_chat_in_this_env) {
      //---core---
      let corelibrary = document.getElementById(corelibrary_Id);
      if (!corelibrary) {
        corelibrary = document.createElement('script');
        corelibrary.id = corelibrary_Id;
        corelibrary.setAttribute(
          'src',
          'https://zammostorbmvsu.blob.core.windows.net/zammo-bot-ui-kit/zammo-bot-ui-kit-latest.min.js'
        );
        corelibrary.setAttribute(
          'cssUrl',
          'https://zammostorbmvsu.blob.core.windows.net/zammo-bot-ui-kit/zammo-bot-ui-kit-latest.min.css'
        );
        corelibrary.setAttribute('zammoApiBaseUrl', 'https://zammo-lausd.azurewebsites.net');
        corelibrary.setAttribute('webChatId', import.meta.env.VITE_ZAMMO_WEBCHAT_ID);
        corelibrary.setAttribute('executionType', 'controlled');
        document.body.appendChild(corelibrary);

        //---custom---

        corelibrary.onload = () => {
          if (!document.getElementById(customscript_Id)) {
            const customscript = document.createElement('script');
            customscript.id = customscript_Id;
            customscript.innerHTML = `
            window.ZammoBotUIKit.onDocumentReady(function () {
              var ZammoBotUIKit = window.ZammoBotUIKit;
  
              var zammoBotUIInstance;
  
              if (!zammoBotUIInstance) {
                zammoBotUIInstance = ZammoBotUIKit.initialize();
                zammoBotUIInstance.initialBotData = JSON.stringify({                
                  customer: "${cookies.access_token}"                  
                });   
                zammoBotUIInstance.run();
                window.zammoBotUIInstance = zammoBotUIInstance;                
              }
            });
            `;
            document.body.appendChild(customscript);
          }
        };
      }
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const corelib = document.getElementById(corelibrary_Id);
      const customscriptblock = document.getElementById(customscript_Id);
      const customExtension = document.getElementById('zammoBotUIKitCustomExtensionScript');
      const chatlauncher = document.getElementById('zammoBotUIComponent');

      if (chatlauncher) {
        document.body.removeChild(chatlauncher);
      }

      if (corelib) {
        document.body.removeChild(corelib);
      }

      if (customscriptblock) {
        document.body.removeChild(customscriptblock);
      }

      if (customExtension) {
        document.body.removeChild(customExtension);
      }
    };
  }, [isChatEligible, cookies.access_token]);

  // START ////////////////////////////////////////////
  // TODO: this is for demo purposes and will be removed soon

  let initialMessage = '';
  if (user && user?.type === 'guardian') {
    const messages = t('chat.guardian', {
      returnObjects: true,
      user: user.firstName || ''
    }) as unknown;
    initialMessage = !messages ? '' : pickRandom(messages as string[]);
  } else if (user && user?.type === 'student') {
    const messages = t('chat.student', {
      returnObjects: true,
      user: user.firstName || ''
    }) as unknown;
    initialMessage = !messages ? '' : pickRandom(messages as string[]);
  }

  useEffect(() => {
    setInitialMessage(initialMessage);
  }, [initialMessage, setInitialMessage]);

  // this is the pseudo nudging of the recommendations
  const isAllowedToAutoOpen =
    !isChatOpen &&
    !hasOpened &&
    user?.type === 'guardian' &&
    location.pathname !== RoutePaths.STUDENT_SELECTOR;

  useEffect(() => {
    if (isAllowedToAutoOpen) {
      setIsChatOpen(true);
      setIsOpenedByNudge(true);

      // this prevent the chat from being opened erroneously during user signoff
      setHasOpened(true);
    }
  }, [setIsChatOpen, setIsOpenedByNudge, isAllowedToAutoOpen]);
  // TODO: make sure to delete this hook too
  // END ////////////////////////////////////////////

  // const isEdButtonAllowed = [
  //   RoutePaths.STUDENT_SELECTOR,
  //   RoutePaths.MESSAGES,
  //   RoutePaths.SIGN_IN,
  //   RoutePaths.GUARDIAN_LOGIN,
  //   RoutePaths.TERMS_AND_CONDITIONS,
  //   RoutePaths.ERROR,
  //   RoutePaths.LTI_AUTHORIZE,
  //   RoutePaths.LTI_SETTINGS,
  //   RoutePaths.LTI_TOOLS_LIST,
  //   RoutePaths.LTI_JWK
  // ].includes(location.pathname as RoutePaths)
  //   ? false
  //   : true;

  //const isChatVisible = isChatOpen && device === 'desktop' && isEdButtonAllowed;

  // TODO: initialMessage is temporary until we have a better solution
  if (!isChatEligible || !initialMessage) return null;

  return null;
  /*
    return isChatVisible ? (
      <iframe
        title="Chat with Ed"
        className={cn(
          'fixed bottom-0 right-7 hidden h-desktop-widget min-h-[400px] w-desktop-widget lg:right-8 lg:flex',
          iframeCls
        )}
        onLoad={onLoad}
        ref={iframeRef}
        src={widgetUrl}></iframe>
    ) : !isEdButtonAllowed ? null : (
      <div
        className={cn(
          'hidden',
          'lg:flex',
          'items-center',
          'fixed',
          'bottom-7',
          'lg:bottom-8',
          'right-7',
          'lg:right-8',
          'bg-secondary-danger',
          'text-white',
          'rounded-full',
          'cursor-pointer',
          buttonCls
        )}
        onClick={onClickHandler}>
        <div className={clsx('ml-1', 'my-1', 'bg-white', 'rounded-full', 'p-1')}>
          <EdLogoWelcome className="h-10 w-10 shrink-0 rounded-full bg-secondary-white object-contain p-0" />
        </div>
        <div
          className={clsx('ml-3', 'pr-6', 'font-poppins', 'font-subhead', 'font-w-4', 'leading-8')}>
          {t('chat.title')}
        </div>
      </div>
    );
    */
};
