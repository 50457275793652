import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationSearch = (defaultRoute: string) => {
  const location = useLocation();
  const combinedRoute = !location.search ? defaultRoute : `${defaultRoute}${location.search}`;
  const [route, setRoute] = useState(combinedRoute);
  useEffect(() => {
    if (route !== combinedRoute) {
      setRoute(combinedRoute);
    }
  }, [route, combinedRoute]);

  return combinedRoute;
};
