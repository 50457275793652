import { ServerRoutePaths } from '@shared/utils';
import { type Student } from '../types';
import { type Request } from './Request';

export class StudentApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getStudents() {
    return this.request.call<Student[]>({ url: ServerRoutePaths.STUDENTS });
  }

  public getStudentById({ id }: { id: string }) {
    return this.request.call<Student>({ url: `${ServerRoutePaths.STUDENTS}/${id}` });
  }

  public getStudentByEmail({ email }: { email: string }) {
    return this.request.call<Student>({
      method: 'post',
      url: ServerRoutePaths.STUDENT_BY_EMAIL,
      data: { email },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
