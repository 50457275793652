import './plugins/datadog-rum';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { defaultRequestProviderValue, RequestProvider } from '@request';
import { ActivitiesProvider } from '@activities';
import { DrawerProvider, NotificationProvider } from '@shared/components';
import { initApp } from '@shared/utils';

import App from './App';
import { I18nProvider } from './i18n/I18nContext';
import AuthProvider from './authentication/AuthContext';
import { StudentProvider } from './student/StudentContext';
import { GuardianProvider } from './guardian/GuardianContext';
import { CalendarProvider } from './shared/components/calendar';

const bootstrap = () => {
  initApp().then(({ isLoggedIn, user }) => {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <StrictMode>
        <I18nProvider language={user?.preferences?.language}>
          <Router>
            <RequestProvider value={defaultRequestProviderValue}>
              <AuthProvider initialUser={user} loggedIn={isLoggedIn}>
                <DrawerProvider>
                  <StudentProvider>
                    <GuardianProvider>
                      <NotificationProvider>
                        <ActivitiesProvider>
                          <CalendarProvider>
                            <App />
                          </CalendarProvider>
                        </ActivitiesProvider>
                      </NotificationProvider>
                    </GuardianProvider>
                  </StudentProvider>
                </DrawerProvider>
              </AuthProvider>
            </RequestProvider>
          </Router>
        </I18nProvider>
      </StrictMode>
    );
  });
};

bootstrap();
