import { createContext, type FC, ReactNode, useState, useCallback } from 'react';
import i18next, { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { type HttpBackendOptions } from 'i18next-http-backend';
import { ENV, getEnv } from '@shared/utils';
import { useDefaultLanguage, useDidMount } from '@shared/hooks';
import { User } from '@shared/types';
import { Language } from 'src/shared/types/language';

type i18nType = ReturnType<typeof createInstance>;

export type I18nT = (
  str: string,
  options?: Parameters<typeof i18next.t>[2]
) => ReturnType<typeof i18next.t>;

type I18nContextType = {
  t: I18nT;
  withPrefix: (prefix: string) => {
    t_: I18nT;
  };
  changeLanguage: (lng: Language) => Promise<void>;
  currentLanguage: Language;
};

export const I18nContext = createContext<I18nContextType>({
  t: i18next.t,
  withPrefix: (prefix: string) => ({ t_: (str: string) => i18next.t(`${prefix}.${str}`, str) }),
  changeLanguage: () => Promise.resolve(),
  currentLanguage: navigator.language.toLowerCase().substring(0, 2) as Language
});

const env = getEnv();

function getTranslationsEnv() {
  if (env === ENV.PROD) {
    return '.';
  } else if (env === ENV.STAGING) {
    return '.stage.';
  }
  return '.dev.';
}

async function createI18nInstanceAndInitialize(lng: string) {
  const i18nInstance = createInstance();
  try {
    await initializeI18nInstance(i18nInstance, lng);
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.log(`Error loading translations for language ${lng}: `, error.message);
    }
    await initializeI18nInstance(i18nInstance, 'en');
  }
  return i18nInstance;
}

async function initializeI18nInstance(i18nInstance: i18nType, lng: string) {
  const loadPath = await getLoadPath(lng);
  return i18nInstance
    .use(initReactI18next)
    .use(Backend)
    .init<HttpBackendOptions>({
      load: 'languageOnly',
      nsSeparator: '§',
      backend: {
        loadPath
      },
      interpolation: {
        escapeValue: false
      }
    });
}

async function getLoadPath(lng: string) {
  const translationsEnv = getTranslationsEnv();
  const envLoadPath = `https://files${translationsEnv}iap.lausd.net/locales/${lng}/translation.json`;
  const localLoadPath = 'http://localhost:5050/i18n/master.json';
  if (env !== ENV.LOCAL || lng !== 'en') {
    return envLoadPath;
  } else {
    try {
      await fetch(localLoadPath);
    } catch (err: unknown) {
      console.warn(
        `Couldn't get local i18n master file. Make sure server is running on i18n repo. `,
        err
      );
      return envLoadPath;
    }
    return localLoadPath;
  }
}

type I18nMapping = {
  [lng: string]: i18nType;
};

const i18nMap: I18nMapping = {};

interface I18nProviderProps {
  children: ReactNode;
  language?: User['preferences']['language'];
}

export const I18nProvider: FC<I18nProviderProps> = ({ children, language }) => {
  const [i18n, setI18n] = useState<i18nType>(i18next);
  const defaultLanguage = useDefaultLanguage();
  const [currentLanguage, setCurrentLanguage] = useState<User['preferences']['language']>(
    language ? language : defaultLanguage
  );

  const changeLanguage = useCallback(
    async (lng: Language) => {
      if (!i18nMap[lng]) {
        i18nMap[lng] = await createI18nInstanceAndInitialize(lng);
      }
      if (i18n.language !== lng) {
        setI18n(i18nMap[lng]);
        setCurrentLanguage(lng);
      }
    },
    [i18n]
  );

  useDidMount(() => {
    changeLanguage(language || defaultLanguage).catch((error) => {
      console.error('Error changing language', error);
    });
  });

  const withPrefix = useCallback(
    (prefix: string) => ({
      t_: (str: string, options: Parameters<typeof i18n.t>[2]) =>
        i18n.t(`${prefix}.${str}`, str, options)
    }),
    [i18n]
  );

  const t = useCallback(
    (str: string, options?: Parameters<typeof i18n.t>[2]) => i18n.t(str, str, options),
    [i18n]
  );

  return (
    <I18nContext.Provider value={{ t, withPrefix, changeLanguage, currentLanguage }}>
      {children}
    </I18nContext.Provider>
  );
};
