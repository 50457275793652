import { type FC, type PropsWithChildren, useEffect } from 'react';

import { NotificationContext } from '../context';
import { useNotificationApi } from '../hooks';
import { useDrawerContext } from '../../drawer';
import { NOTIFICATIONS_DRAWER_ID } from '../../../utils';
import { type Notification } from '@request';
import { useAuth } from '@authentication';

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const drawerContext = useDrawerContext();
  const { user } = useAuth();
  const {
    notifications,
    deleteNotification,
    updateNotification,
    addNotification,
    getMoreNotifications,
    setViwed
  } = useNotificationApi((user?.id as string) || '');
  const value = {
    notifications,
    deleteNotification,
    updateNotification,
    getMoreNotifications,
    setViwed,
    hasNews: notifications.filter((n) => !n.viewed).length > 0
  };

  useEffect(() => {
    // TO DO: Save this URL in the env vars when the BE is done.
    const wsUrl = import.meta.env.VITE_NOTIFICATIONS_SERVER_URL;
    let ws: WebSocket | null = null;

    if (user?.id && user.type) {
      ws = new WebSocket(wsUrl);

      ws.onmessage = (event) => {
        const newNotification: Notification = JSON.parse(event.data);

        addNotification(newNotification);

        if (drawerContext.state[NOTIFICATIONS_DRAWER_ID].isOpen) {
          updateNotification(newNotification.id);
        }
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.type, addNotification]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
