import { Navigate, Outlet } from 'react-router-dom';
import { useHomePage, useIsChatEligible } from '@shared/hooks';

export const ProtectedChatRoute = () => {
  const isChatEligible = useIsChatEligible();
  const homePage = useHomePage();

  if (!isChatEligible) {
    return <Navigate to={homePage} />;
  }
  return <Outlet />;
};

export default ProtectedChatRoute;
