import { datadogRum } from '@datadog/browser-rum';

const isLocalHost = window.location.hostname === 'localhost';

class DatadogClient {
  constructor() {
    !isLocalHost && this.init();
  }

  private init() {
    datadogRum.init({
      applicationId: 'ed52321f-be56-48a9-ab88-79ebc5e961c1',
      clientToken: 'pubad0c86edd6abd2e4577411b0c6239cf0',
      site: 'us5.datadoghq.com',
      service: 'lausd-mobile',
      env: import.meta.env.VITE_DD_ENV,
      sessionSampleRate: 100,
      premiumSampleRate: 20,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      version: import.meta.env.VITE_DD_VERSION,
      // Need to enable CORS headers first in server
      // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#how-are-rum-resources-linked-to-traces
      allowedTracingUrls: [
        'https://api.dev.iap.lausd.net',
        'https://api.stage.iap.lausd.net',
        'https://api.iap.lausd.net'
      ]
    });

    datadogRum.startSessionReplayRecording();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUser(user: any) {
    !isLocalHost && user && datadogRum.setUser(user);
  }

  removeUser() {
    !isLocalHost && datadogRum.removeUser();
  }
}

const datadogClient = new DatadogClient();
export default datadogClient;
