export enum BrowserType {
  IE,
  EDGE,
  CHROME,
  SAFARI,
  FIREFOX
}

const defaultBrowserType = BrowserType.CHROME;

export const useBrowserType = () => {
  if (isInternetExplorer()) {
    return BrowserType.IE;
  }
  if (isEdge()) {
    return BrowserType.EDGE;
  }
  if (isChrome()) {
    return BrowserType.CHROME;
  }
  if (isFirefox()) {
    return BrowserType.FIREFOX;
  }
  if (isSafari()) {
    return BrowserType.SAFARI;
  }
  return defaultBrowserType;
};

function isInternetExplorer() {
  return /Trident|MSIE/.test(window.navigator.userAgent);
}

function isEdge() {
  return /Edge/.test(window.navigator.userAgent);
}

function isChrome() {
  return /Chrome/.test(window.navigator.userAgent) && !/Edge/.test(window.navigator.userAgent);
}
// Check if the browser is Firefox
function isFirefox() {
  return /Firefox/.test(window.navigator.userAgent);
}

function isSafari() {
  return (
    /Safari/.test(window.navigator.userAgent) &&
    !/Chrome/.test(window.navigator.userAgent) &&
    !/Edge/.test(window.navigator.userAgent)
  );
}
