export enum CleverClient {
  IXL,
  DISCOVERY_EDUCATION,
  AMPLIFY,
  RETHINK_EDUCATION,
  MY_MATH_ACADEMY,
  MY_READING_ACADEMY
}

const CleverClientId: Record<CleverClient, string> = {
  [CleverClient.IXL]: '3513be842ce24d16f779',
  [CleverClient.DISCOVERY_EDUCATION]: '4ec8e29af5487b83bf5a',
  [CleverClient.AMPLIFY]: '317e9b544fc23b81eb5e',
  [CleverClient.RETHINK_EDUCATION]: 'fff36f1485194d8e55f1',
  [CleverClient.MY_MATH_ACADEMY]: '092e0c54da52e366317b',
  [CleverClient.MY_READING_ACADEMY]: '063ea039c5db6787e723'
};

const DISTRICT_ID = '51e76ab1d93412f47b000c32';

const CleverUrlPrefix = 'https://clever.com/oauth/instant-login?skip=1&specify_auth=saml';

const CleverUrlMap = Object.keys(CleverClient).reduce(
  (acc, val) => {
    const client = val as unknown as CleverClient;
    const clientIdParam = `&client_id=${encodeURIComponent(CleverClientId[client])}`;
    const districtIdParam = `&district_id=${encodeURIComponent(DISTRICT_ID)}`;
    acc[client] = CleverUrlPrefix + clientIdParam + districtIdParam;
    return acc;
  },
  {} as Record<CleverClient, string>
);

function getClientFromUrl(url: string): CleverClient | null {
  const loweredUrl = url.toLowerCase();
  if (loweredUrl.includes('ixl.com')) {
    return CleverClient.IXL;
  }
  if (loweredUrl.includes('discoveryeducation.com')) {
    return CleverClient.DISCOVERY_EDUCATION;
  }
  if (loweredUrl.includes('amplify.com')) {
    return CleverClient.AMPLIFY;
  }
  if (loweredUrl.includes('rethinked.com')) {
    return CleverClient.RETHINK_EDUCATION;
  }
  if (loweredUrl.includes('mymathacademy.com')) {
    return CleverClient.MY_MATH_ACADEMY;
  }
  if (loweredUrl.includes('myreadingacademy.com')) {
    return CleverClient.MY_READING_ACADEMY;
  }
  return null;
}

export const useCleverSsoUrl = (url: string) => {
  const client = getClientFromUrl(url);
  if (client === null) {
    return '';
  }
  return CleverUrlMap[client];
};
