import { useContext } from 'react';
import { ChatContext } from '../ChatContext';

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('No Chat Provider');
  }
  return context;
};
