import { type FC, type PropsWithChildren } from 'react';

import {
  AuthenticationApi,
  GuardianApi,
  LtiToolApi,
  NotificationsApi,
  SchoolApi,
  StudentApi,
  Request,
  ResourceApi,
  UserApi
} from './components';

const defaultConfig = { baseURL: import.meta.env.VITE_API_BASE_URL };

import { createContext } from 'react';

interface RequestContextType {
  // request: Request;
  auth: {
    api: AuthenticationApi;
    request: Request;
  };
  guardian: {
    api: GuardianApi;
    request: Request;
  };
  resource: {
    api: ResourceApi;
    request: Request;
  };
  school: {
    api: SchoolApi;
    request: Request;
  };
  student: {
    api: StudentApi;
    request: Request;
  };
  user: {
    api: UserApi;
    request: Request;
  };
  notifications: {
    api: NotificationsApi;
    request: Request;
  };
  lti: {
    api: LtiToolApi;
    request: Request;
  };
}
export const RequestContext = createContext<RequestContextType | null>(null);

// each api instance has its own request instance
const guardianRequest = new Request(Object.assign({}, defaultConfig), 'guardian');
const resourceRequest = new Request(Object.assign({}, defaultConfig), 'resource');
const schoolRequest = new Request(Object.assign({}, defaultConfig), 'school');
const studentRequest = new Request(Object.assign({}, defaultConfig), 'student');
const userRequest = new Request(Object.assign({}, defaultConfig), 'user');
const ltiRequest = new Request(Object.assign({}, defaultConfig), 'lti');
// TODO: Identify the dev specific endpoint
const notificationsRequest = new Request(
  {
    baseURL: import.meta.env.VITE_API_BASE_URL_V2
  },
  'notifications'
);
// the auth api instance has its own request instance without the custom response interceptors
const authRequest = new Request(Object.assign({}, defaultConfig), 'auth', false);
const authApi = new AuthenticationApi(authRequest);
const guardianApi = new GuardianApi(guardianRequest);
const ltiToolApi = new LtiToolApi(ltiRequest);
const notificationsApi = new NotificationsApi(notificationsRequest);
const resourceApi = new ResourceApi(resourceRequest);
const schoolApi = new SchoolApi(schoolRequest);
const studentApi = new StudentApi(studentRequest);
const userApi = new UserApi(userRequest);

export const defaultRequestProviderValue: RequestContextType = {
  auth: {
    api: authApi,
    request: authRequest
  },
  guardian: {
    api: guardianApi,
    request: guardianRequest
  },
  resource: {
    api: resourceApi,
    request: resourceRequest
  },
  school: {
    api: schoolApi,
    request: schoolRequest
  },
  student: {
    api: studentApi,
    request: studentRequest
  },
  user: {
    api: userApi,
    request: userRequest
  },
  notifications: {
    api: notificationsApi,
    request: notificationsRequest
  },
  lti: {
    api: ltiToolApi,
    request: ltiRequest
  }
};

export interface RequestProviderProps {
  value: RequestContextType;
}

export const RequestProvider: FC<PropsWithChildren<RequestProviderProps>> = ({
  children,
  value
}) => <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
