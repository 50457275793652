import { Navigate, Outlet } from 'react-router-dom';
import { RoutePaths } from '@shared/utils';
import { useIsGuardian } from '@shared/hooks';

export const ProtectedGuardianRoute = () => {
  const isGuardian = useIsGuardian();

  if (!isGuardian) {
    return <Navigate to={RoutePaths.STUDENT_HOME} />;
  }
  return <Outlet />;
};

export default ProtectedGuardianRoute;
