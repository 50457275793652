import { type FC, type PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Caret } from '@shared/icons';

import { type DropdownButtonProps } from '../types';

export const DropdownButton: FC<PropsWithChildren<DropdownButtonProps>> = ({
  children,
  width = 'auto',
  onClick,
  isOpen
}) => (
  <div style={{ width }}>
    <button
      type="button"
      className="flex w-full items-center justify-between rounded-md border border-solid border-solid-gray bg-secondary-white px-4 py-2 shadow-sm"
      onClick={onClick}>
      {children}
      <span
        className={clsx({ 'rotate-180': isOpen }, 'absolute right-2 transition-all duration-100')}>
        <Caret fill="#374151" />
      </span>
    </button>
  </div>
);
