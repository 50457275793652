import { type ApiResponse } from '@request';
import { type Request } from './Request';
import { type Guardian } from '@shared/types';
import { ServerRoutePaths } from '@shared/utils';

export class GuardianApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getGuardians() {
    return this.request.call<ApiResponse<Guardian[]>>({ url: ServerRoutePaths.GUARDIANS });
  }

  public getGuardianById({ id }: { id: string }) {
    return this.request.call<ApiResponse<Guardian>>({
      url: `${ServerRoutePaths.GUARDIANS}/${id}`
    });
  }
}
