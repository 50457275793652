import { ServerRoutePaths } from '@shared/utils';
import { type Resource } from '../types';
import { type Request } from './Request';

export class ResourceApi {
  private request: Request;

  constructor(request: Request) {
    this.request = request;
  }

  public getResources() {
    return this.request.call<Resource[]>({ url: ServerRoutePaths.RESOURCES });
  }

  public getResourceById({ id }: { id: string }) {
    return this.request.call<Resource>({ url: `${ServerRoutePaths.RESOURCES}/${id}` });
  }
}
