import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';

import { type RequestConfig } from '../types';
import { RoutePaths } from '@shared/utils';

export class Request {
  private axios: AxiosInstance;
  private cookies = new Cookies();
  private name: string;

  constructor(config: RequestConfig, name = '', useResInterceptor = true) {
    const { baseURL, ...configRest } = config;
    this.name = name;

    this.axios = axios.create({
      ...configRest,
      baseURL: this.formatUrl(baseURL)
    });

    if (useResInterceptor) {
      this.addResInterceptor();
    }
  }

  public async call<Res = unknown>(requestConfig: AxiosRequestConfig): Promise<AxiosResponse<Res>> {
    const accessToken = this.cookies.get<string>('access_token');
    const config = {
      ...requestConfig,
      headers: {
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        ...(requestConfig.headers || {}),
        'X-Platform': 'web'
      }
    };

    return this.axios.request(config);
  }

  private addResInterceptor() {
    this.axios.interceptors.response.use(
      (r: AxiosResponse) => r,
      (error) => {
        if (error?.response?.status === 401) {
          console.info(`Interceptor ${this.name} 401 error`, error);
          window.history.pushState({}, '', RoutePaths.LOGOUT);
          window.history.go();
          return;
        }
        if (error) {
          return Promise.reject(error);
        }
      }
    );
  }

  private formatUrl(url: string) {
    if (url?.charAt(url.length - 1) === '/') {
      return url.slice(0, -1);
    }

    return url;
  }
}
