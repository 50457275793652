import { type FC, useState, useRef, useCallback } from 'react';

import { Modal, ModalBody, Dropdown, Button, Loading } from '@shared/components';
import { useSupportedLanguages } from '@shared/hooks';
import { Language } from '@shared/types';
import { useI18n } from './hooks';

interface LangModalProps {
  onSave: (lang: Language) => void | Promise<void>;
}
const initialLang = navigator.language.toLowerCase().substring(0, 2) as Language;

export const LangModal: FC<LangModalProps> = ({ onSave }) => {
  const supportedLanguages = useSupportedLanguages();

  const { withPrefix } = useI18n();
  const { t_ } = withPrefix('settings.preferences.languageModal');
  const [dropIsOpen, setDropIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const lang = useRef(initialLang);
  const onClickSave = useCallback(async () => {
    try {
      setLoading(true);
      await onSave(lang.current as Language);
    } catch (error) {
      console.error('Failed to save language', error);
    } finally {
      setLoading(false);
    }
  }, [onSave]);

  if (loading) {
    return <Loading show={loading} />;
  }

  return (
    <Modal>
      <ModalBody>
        <div className="px-8 pb-6 pt-4">
          <h6 className="topic-2 mb-4 leading-6">{t_('preferredLanguage')}</h6>
          <div className="flex justify-between">
            <Dropdown
              placeholder="English"
              isOpen={dropIsOpen}
              isSelect
              onClose={() => setDropIsOpen(false)}
              onOpen={() => setDropIsOpen((prev) => !prev)}
              width="6.5625rem"
              options={supportedLanguages.map(({ code, nativeName }) => ({
                label: nativeName,
                onClick: () => {
                  lang.current = code;
                  setDropIsOpen(false);
                }
              }))}
            />
            <Button className="w-[6.55344rem] px-4 py-2" onClick={onClickSave}>
              {t_('save')}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
