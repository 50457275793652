import React, { useState, useRef, useCallback, useEffect, createContext } from 'react';
import { Cookies } from 'react-cookie';
import { useI18n } from '@i18n'; // Replace with your actual path
import { useAuth } from '@authentication'; // Replace with your actual path
import { CONNECTION_CLOSED, INIT, TOGGLE_VOLUME } from '@shared/types';

// TODO: this setup of using the environment variables from the same repo is bad
// need to update this process to use ENV from a CI/CD pipeline
const model_id = import.meta.env.VITE_TTS_MODEL_ID;
const voice_id = import.meta.env.VITE_TTS_VOICE_ID;
const output_format = import.meta.env.VITE_TTS_OUTPUT_FORMAT;
const optimize_streaming_latency = Number(import.meta.env.VITE_TTS_OPTIMIZE_STREAMING_LATENCY);
const apiKey = import.meta.env.VITE_TTS_API_KEY;
const translationUrl = `https://api.elevenlabs.io/v1/text-to-speech/${voice_id}/stream?output_format=${output_format}&optimize_streaming_latency=${optimize_streaming_latency}`;

const cookies = new Cookies();
const widgetUrl = import.meta.env.VITE_CHAT_WIDGET_URL;
const serverUrl = import.meta.env.VITE_CHAT_SERVER_URL;

// Define the context shape
type ChatContextType = {
  widgetUrl: string;
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  onLoad: () => void;
  device: 'desktop' | 'mobile';
  setDevice: (device: 'desktop' | 'mobile') => void;
  initialMessage: string;
  setInitialMessage: (message: string) => void;
  setIsOpenedByNudge: (isOpenedByNudge: boolean) => void;
  toggleVisibility: () => void;
};

// Create the context with a default value
export const ChatContext = createContext<ChatContextType>({
  widgetUrl: '',
  isChatOpen: false,
  setIsChatOpen: () => null,
  iframeRef: { current: null },
  onLoad: () => null,
  device: 'desktop',
  setDevice: () => null,
  initialMessage: '',
  setInitialMessage: () => null,
  setIsOpenedByNudge: () => null,
  toggleVisibility: () => null
});

interface ChatProviderProps {
  children: React.ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { currentLanguage, t } = useI18n();
  const { user, updatePreferences } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [device, setDevice] = useState<'desktop' | 'mobile'>('desktop'); // or 'mobile', depending on your logic
  const [initialMessage, setInitialMessage] = useState(
    t('chat.initialMessage', { returnObjects: true, user: user?.firstName || '' }) || ''
  ); // or 'mobile', depending on your logic
  const [isOpenedByNudge, setIsOpenedByNudge] = useState(false);

  const idToken = cookies.get('id_token');
  const accessToken = cookies.get('access_token');
  // TODO: remove toggleVisibility as there is no corresponding message in the iframe
  const toggleVisibility = useCallback(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      // The contentWindow property returns the Window object of an iframe
      // You would then use postMessage on this window object
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'TOGGLE_VISIBILITY'
        },
        widgetUrl
      );
      // The `targetOrigin` should be the origin of the iframe's document to ensure security
      // Use '*' if the iframe can be from various origins or you're not concerned about the security implications
    }
  }, [iframeRef]);

  useEffect(() => {
    if (
      iframeRef.current &&
      iframeRef.current.contentWindow &&
      typeof currentLanguage !== 'undefined' &&
      typeof user?.preferences.language !== undefined &&
      user?.preferences.language !== currentLanguage
    ) {
      // The contentWindow property returns the Window object of an iframe
      // You would then use postMessage on this window object
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify({
          type: 'UPDATE_LANGUAGE',
          content: {
            lang: currentLanguage,
            i18n: {
              input: {
                placeholder: t('chat.input.placeholder')
              },
              disclaimer: t('chat.disclaimer'),
              server: {
                busy: t('chat.server.busy'),
                disconnected: t('chat.server.disconnected'),
                timeout: t('chat.server.timeout')
              }
            }
          }
        }),
        widgetUrl
      );
      // The `targetOrigin` should be the origin of the iframe's document to ensure security
      // Use '*' if the iframe can be from various origins or you're not concerned about the security implications
    }
  }, [iframeRef, t, currentLanguage, user?.preferences.language]);

  const onLoad = useCallback(() => {
    if (
      iframeRef.current &&
      iframeRef.current.contentWindow &&
      user &&
      idToken &&
      accessToken &&
      initialMessage &&
      serverUrl &&
      device
    ) {
      const message: INIT = {
        type: 'INIT',
        content: {
          is_opened_by_nudge: isOpenedByNudge,
          initial_message: initialMessage,
          id_token: idToken,
          access_token: accessToken,
          device,
          server: serverUrl,
          text_to_speech: {
            volume_enabled: !!user.preferences.volume_enabled,
            url: translationUrl,
            api_key: apiKey,
            model_id: model_id,
            voice_settings: {
              similarity_boost: 0.35,
              stability: 0.5,
              style: 0.3
            }
          },
          i18n: {
            input: {
              placeholder: t('chat.input.placeholder')
            },
            disclaimer: t('chat.disclaimer'),
            server: {
              busy: t('chat.server.busy'),
              disconnected: t('chat.server.disconnected'),
              timeout: t('chat.server.timeout')
            },
            feedback: {
              options: {
                // keys are snake_cased because they are used as values to help the data team tweak the algorithm
                incorrect: t('chat.feedback.options.incorrect'),
                not_enough_information: t('chat.feedback.options.notEnoughInformation'),
                something_else: t('chat.feedback.options.somethingElse')
              },
              tellUsMore: t('chat.feedback.tellUsMore'),
              thankYouForYourFeedback: t('chat.feedback.thankYouForYourFeedback')
            }
          },
          user
        }
      };
      iframeRef.current.contentWindow.postMessage(JSON.stringify(message), '*');
    }
  }, [iframeRef, user, accessToken, device, idToken, t, initialMessage, isOpenedByNudge]);

  useEffect(() => {
    function onMessage(event: MessageEvent) {
      const data = event.data as CONNECTION_CLOSED | TOGGLE_VOLUME;
      if (data.type === 'CONNECTION_CLOSED') {
        setIsChatOpen(false);
      } else if (data.type === 'TOGGLE_VOLUME') {
        const newPreferences = Object.assign({}, user?.preferences, {
          volume_enabled: data.content.volume_enabled
        });
        updatePreferences(newPreferences)
          .then(() => {
            console.info('success updating volume_enabled preferences');
          })
          .catch((err) => {
            console.log('error updating volume_enabled preferences', err);
          });
      }
    }

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [user, updatePreferences]);
  return (
    <ChatContext.Provider
      value={{
        widgetUrl,
        initialMessage,
        setInitialMessage,
        isChatOpen,
        setIsChatOpen,
        iframeRef,
        onLoad,
        device,
        setDevice,
        setIsOpenedByNudge,
        toggleVisibility
      }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
