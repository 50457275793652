import { useState, useEffect, useCallback } from 'react';

import { useRequest, Notification } from '@request';

const DEFAULT_DATA = {
  data: []
};

export const useNotificationApi = (userId: string) => {
  const {
    notifications: { api: notificationsApi }
  } = useRequest();
  const [{ data: notifications, nextToken }, setData] = useState<{
    data: Notification[];
    nextToken?: string | undefined;
  }>(DEFAULT_DATA);
  const getNotifications = (userId: string) =>
    notificationsApi.getNotifications({
      userId,
      limit: 20,
      ascending: false
    });
  const getMoreNotifications = async (nt: string) => {
    const res = await notificationsApi.getNotifications({
      userId,
      limit: 20,
      ascending: false,
      nextToken: nt
    });

    setData((prev) => ({
      data: [...res.data.data, ...prev.data],
      nextToken: res.data.nextToken
    }));
  };
  const updateNotification = async (id: string, viewed = true) => {
    await notificationsApi.updateNotification({
      id,
      user: {
        id: userId
      },
      viewed
    });

    setData((prev) => ({
      ...prev,
      data: prev.data.map((n) => ({
        ...n,
        viewed
      }))
    }));
  };
  const deleteNotification = async (notificationId: string) => {
    await notificationsApi.deleteNotification({
      notificationId,
      userId
    });

    setData((prev) => ({
      ...prev,
      data: prev.data.filter((n) => n.id !== notificationId)
    }));
  };
  const addNotification = useCallback((n: Notification) => {
    setData((prev) => ({
      ...prev,
      data: [n, ...prev.data]
    }));
  }, []);
  const setViwed = () => {
    const notViewdIds = notifications.filter((n) => !n.viewed).map((n) => n.id);

    setData((prev) => ({
      ...prev,
      data: prev.data.map((n) => ({
        ...n,
        viewed: true
      }))
    }));

    return Promise.all(
      notViewdIds.map((id) =>
        notificationsApi
          .updateNotification({
            id,
            user: {
              id: userId
            },
            viewed: true
          })
          .then((res) => res.data)
      )
    );
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await getNotifications(userId);
        if (res) {
          setData(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (userId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    notifications,
    nextToken,
    getNotifications,
    getMoreNotifications,
    deleteNotification,
    updateNotification,
    addNotification,
    setViwed
  };
};
