import {
  type FC,
  type PropsWithChildren,
  useState,
  createContext,
  useEffect,
  useCallback
} from 'react';

import type { ActivityStatus } from '@activities';
import { Guardian, User } from '@shared/types';
import { useAuth } from '@authentication';
import { useRequest } from '@request';

type GuardianContextType = {
  guardian: Guardian | null;
  updateActivitiesStatus: (activitiesStatus: ActivityStatus[]) => Promise<void>;
};

export const GuardianContext = createContext<GuardianContextType>({
  guardian: null,
  updateActivitiesStatus: () => Promise.resolve()
});

export const GuardianProvider: FC<PropsWithChildren> = ({ children }) => {
  const [guardian, setGuardian] = useState<Guardian | null>(null);

  const auth = useAuth();
  const {
    guardian: { api: guardianApi },
    user: { api: userApi }
  } = useRequest();

  const fetchGuardian = useCallback(
    async (user: User) => {
      const response = await guardianApi.getGuardianById({ id: String(user.id) });
      const guardian = response.data.data;
      setGuardian(guardian);
    },
    [guardianApi]
  );

  useEffect(() => {
    if (auth.user && auth.user.type === 'guardian') {
      fetchGuardian(auth.user).catch(console.error);
    }
  }, [auth.user, fetchGuardian]);

  const updateActivitiesStatus = useCallback(
    async (activitiesStatus: ActivityStatus[]) => {
      const updatedGuardian = {
        ...guardian,
        activitiesStatus
      } as Guardian;

      setGuardian(updatedGuardian);
      if (guardian?.guardian_id) {
        await userApi.saveActivitiesStatus({ userId: guardian.guardian_id, activitiesStatus });
      }
    },
    [guardian, userApi]
  );

  return (
    <GuardianContext.Provider value={{ guardian, updateActivitiesStatus }}>
      {children}
    </GuardianContext.Provider>
  );
};
