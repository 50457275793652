import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks';
import { useEncodeLocationSearch } from '@shared/hooks';
import { RoutePaths } from '@shared/utils';

export const ProtectedRoute = () => {
  const { isLoggedIn, user, setIsLanguageModalOpen } = useAuth();
  const from = useEncodeLocationSearch('from');

  if (isLoggedIn === false) {
    const redirectTo = `${RoutePaths.SIGN_IN}${from}`;
    return <Navigate to={redirectTo} />;
  }
  if (isLoggedIn === true && user && user.preferences && !user.preferences.language) {
    setIsLanguageModalOpen(true);
  }
  if (
    isLoggedIn === true &&
    user &&
    user.type === 'guardian' &&
    user.preferences &&
    !user.preferences.terms_and_conditions
  ) {
    const redirectTo = `${RoutePaths.TERMS_AND_CONDITIONS}${from}`;
    return <Navigate to={redirectTo} />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
