import { type FC } from 'react';
import { createPortal } from 'react-dom';

import { Spinner } from './Spinner';
import { type LoadingProps } from '../types';

export const Loading: FC<LoadingProps> = ({ show }) => {
  const el = (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center overflow-auto bg-secondary-black/[.40]">
      <Spinner />
    </div>
  );

  return show ? createPortal(el, document.body) : null;
};
