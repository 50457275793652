import { type FC, type PropsWithChildren } from 'react';
import clsx from 'clsx';

import { type ButtonProps } from '../types';

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    className={clsx(
      className,
      'topic-3 rounded-md bg-primary-link-blue text-secondary-white shadow-sm hover:brightness-95 active:brightness-90 disabled:bg-secondary-black disabled:opacity-40'
    )}
    {...props}>
    {children}
  </button>
);
