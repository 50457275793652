import { type FC } from 'react';

import { type DropdownItemProps } from '../types';

export const DropdownItem: FC<DropdownItemProps> = ({ label, onClick }) => (
  <li>
    <button
      type="button"
      onClick={onClick}
      className="w-full p-2 text-left hover:bg-slate-50 active:bg-slate-100">
      {label}
    </button>
  </li>
);
